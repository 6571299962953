<template>
  <div class="admin">
    <div class="mianbao">
      <div class="breadcrumb" v-if="this.$route.query.uname !== '查看'">
        <span style="color: #fff"> 综合除治 > </span>
        <span style="color: #fff"> 除治详情> </span>
        <span style="color: #016ae0"> 查看过程 </span>
      </div>
      <div class="breadcrumb" v-if="this.$route.query.uname === '查看'">
        <span style="color: #fff"> 首页> </span>
        <span style="color: #fff"> 疫木列表> </span>
        <span style="color: #016ae0"> 查看 </span>
      </div>
      <a-button type="primary" @click="handleBack" icon="rollback"
        >返回</a-button
      >
    </div>
    <div class="content">
      <div class="title">疫木信息</div>
      <div class="table">
        <div class="num1">
          <div class="binahao">疫木编号</div>
          <div class="num">
            {{ treeId }}
          </div>
          <div class="binahao" style="border-left: 1px solid #005ea1">
            所属项目
          </div>
          <div class="name">
            {{ details.projectName }}
          </div>
          <div class="binahao" style="border-left: 1px solid #005ea1">
            作业人员
          </div>
          <div class="nitck_name">
            {{ details.groupName }}
          </div>
        </div>
        <div class="time1">
          <div class="binahao">创建时间</div>
          <div class="num">
            {{ details.createTime | timeChange }}
          </div>
          <div class="binahao" style="border-left: 1px solid #005ea1">
            林业小班
          </div>
          <div class="name">
            {{ details.treeClass }}
          </div>
          <div class="binahao" style="border-left: 1px solid #005ea1">
            地径
          </div>
          <div class="nitck_name">
            {{ (details.diameter-0).toFixed(0) }}cm
          </div>
        </div>
        <div class="beizhu1">
          <div class="binahao">备注</div>
          <div class="beizhu">
            {{ details.note ? details.note : "暂无" }}
          </div>
        </div>
      </div>
      <div class="title">除治过程</div>
      <div class="liucheng">
        <a-timeline>
          <a-timeline-item v-if="detail.beforeImg.img_list.length !== 0">
            <img
              slot="dot"
              v-if="detail.beforeImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.beforeImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">砍伐前</div>
              <div class="content1">
                <div class="img" v-if="detail.beforeImg.img_list.length > 0">
                  <viewer :images="detail.beforeImg.img_list">
                     <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.beforeImg.img_list"
                      :key="item"
                    />
                  </viewer>
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.beforeImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间：{{detail.beforeImg.createTime}} -->
                  </div>
                  <div class="">作业人员：{{ detail.beforeImg.work_user }}</div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item v-if="detail.afterImg.img_list.length !== 0">
            <img
              slot="dot"
              v-if="detail.afterImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.afterImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">
                {{ after1 }}
              </div>
              <div class="content1">
                <div class="img" v-if="detail.afterImg.img_list.length > 0">
                  <viewer :images="detail.afterImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.afterImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.afterImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间：{{detail.afterImg.createTime}} -->
                  </div>
                  <div class="">作业人员：{{ detail.afterImg.work_user }}</div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item v-if="detail.subsectionImg.img_list.length !== 0">
            <img
              slot="dot"
              v-if="detail.subsectionImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.subsectionImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">
                {{ subsection1 }}
              </div>
              <div class="content1">
                <div
                  class="img"
                  v-if="detail.subsectionImg.img_list.length > 0"
                >
                  <viewer :images="detail.subsectionImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.subsectionImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.subsectionImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间：{{detail.subsectionImg.createTime}} -->
                  </div>
                  <div class="">
                    作业人员：{{ detail.subsectionImg.work_user }}
                  </div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item
            v-if="detail.packageImg.img_list.length !== 0 && projectIds !== 7"
          >
            <img
              slot="dot"
              v-if="detail.packageImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.packageImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">枝丫打包</div>
              <div class="content1">
                <div class="img" v-if="detail.packageImg.img_list.length > 0">
                  <viewer :images="detail.packageImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.packageImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.packageImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间：{{detail.packageImg.createTime}} -->
                  </div>
                  <div class="">
                    作业人员：{{ detail.packageImg.work_user }}
                  </div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item
            v-if="
              detail.encapsulationImg.img_list.length !== 0 && projectIds !== 7
            "
          >
            <img
              slot="dot"
              v-if="detail.encapsulationImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.encapsulationImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">封桩后</div>
              <div class="content1">
                <div
                  class="img"
                  v-if="detail.encapsulationImg.img_list.length > 0"
                >
                  <viewer :images="detail.encapsulationImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.encapsulationImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.encapsulationImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间：{{detail.encapsulationImg.createTime}} -->
                  </div>
                  <div class="">
                    作业人员：{{ detail.encapsulationImg.work_user }}
                  </div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item v-if="detail.diameterImg.img_list.length !== 0">
            <img
              slot="dot"
              v-if="detail.diameterImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.diameterImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">地径测量</div>
              <div class="content1">
                <div class="img" v-if="detail.diameterImg.img_list.length > 0">
                  <viewer :images="detail.diameterImg.img_list">
                     <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.diameterImg.img_list"
                      :key="item"
                    />
                  </viewer>
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.diameterImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间：{{detail.diameterImg.createTime}} -->
                  </div>
                  <div class="">作业人员：{{ detail.diameterImg.work_user }}</div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <!-- <a-timeline-item v-if="detail.loadImg.img_list.length!==0">
						<img slot="dot" v-if="detail.loadImg.img_list.length>0" src="../../assets/finish_01.png">
						<img slot="dot" v-if="detail.loadImg.img_list.length==0" src="../../assets/no_finish02.png">
						<div class="kanfaqian">
							<div class="title">
								运输中
							</div>
							<div class="content1">
								<div class="img" v-if="detail.loadImg.img_list.length>0">

									<viewer :images="detail.loadImg.img_list">
										      <img
											style="width: 200px;height:140px;margin-right: 20px;margin-top: 20px;"
											:src=" api+item" v-for="item in detail.loadImg.img_list" :key="item">
										  </viewer>

								</div>
								<div style="color: red;" class="img" v-if="detail.loadImg.img_list.length==0">
									未上传
								</div>
								<div class="time">
									<div>
										时间：{{detail.loadImg.createTime}}
									</div>
									<div class="">
										作业人员：{{detail.loadImg.work_user}}
									</div>
								</div>
							</div>
						</div>
					</a-timeline-item> -->
          <a-timeline-item
            v-if="detail.loadImg.img_list.length !== 0 && projectIds !== 7"
          >
            <img
              slot="dot"
              v-if="detail.loadImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.loadImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">运输装车</div>
              <div class="content1">
                <div class="img" v-if="detail.loadImg.img_list.length > 0">
                  <viewer :images="detail.loadImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.loadImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.loadImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间:{{detail.loadImg.createTime}} -->
                  </div>
                  <div class="">作业人员:{{ detail.loadImg.work_user }}</div>
                </div>
              </div>
            </div>
          </a-timeline-item>

          <a-timeline-item
            v-if="detail.unloadImg.img_list.length !== 0 && projectIds !== 7"
          >
            <img
              slot="dot"
              v-if="detail.unloadImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.unloadImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">运输卸车</div>
              <div class="content1">
                <div class="img" v-if="detail.unloadImg.img_list.length > 0">
                  <viewer :images="detail.unloadImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.unloadImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.unloadImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间:{{detail.unloadImg.createTime}} -->
                  </div>
                  <div class="">作业人员:{{ detail.unloadImg.work_user }}</div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item
            v-if="
              detail.pulverizerImg.img_list.length !== 0 && projectIds !== 7
            "
          >
            <img
              slot="dot"
              v-if="detail.pulverizerImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.pulverizerImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">粉碎</div>
              <div class="content1">
                <div
                  class="img"
                  v-if="detail.pulverizerImg.img_list.length > 0"
                >
                  <viewer :images="detail.pulverizerImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.pulverizerImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.pulverizerImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间:{{detail.pulverizerImg.createTime}} -->
                  </div>
                  <div class="">
                    作业人员:{{ detail.pulverizerImg.work_user }}
                  </div>
                </div>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item
            v-if="
              detail.oveyImg.img_list.length !== 0 && projectIds !== 7
            "
          >
            <img
              slot="dot"
              v-if="detail.oveyImg.img_list.length > 0"
              src="../../assets/finish_01.png"
            />
            <img
              slot="dot"
              v-if="detail.oveyImg.img_list.length == 0"
              src="../../assets/no_finish02.png"
            />
            <div class="kanfaqian">
              <div class="title">奥维定位</div>
              <div class="content1">
                <div
                  class="img"
                  v-if="detail.oveyImg.img_list.length > 0"
                >
                  <viewer :images="detail.oveyImg.img_list">
                          <img
                      style="
                        width: 200px;
                        height: 140px;
                        margin-right: 20px;
                        margin-top: 20px;
                      "
                      :src="api + item"
                      v-for="item in detail.oveyImg.img_list"
                      :key="item"
                    />
                      </viewer
                  >
                </div>
                <div
                  style="color: red"
                  class="img"
                  v-if="detail.oveyImg.img_list.length == 0"
                >
                  未上传
                </div>
                <div class="time">
                  <div>
                    <!-- 时间:{{detail.oveyImg.createTime}} -->
                  </div>
                  <div class="">
                    作业人员:{{ detail.oveyImg.work_user }}
                  </div>
                </div>
              </div>
            </div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import img from "../../assets/u530.png";
import moment from "moment";
export default {
  data() {
    return {
      treeId: "",
      details: {},
      after1: "", //树桩截面？封桩后
      subsection1: "", //树桩分段？封土后
      projectIds: 0,
      detail: {
        // 执行优先级导致的undefined
		    diameterImg: {
		      img_list: [],
		    },
        beforeImg: {
          img_list: [],
        },
        afterImg: {
          img_list: [],
        },
        subsectionImg: {
          img_list: [],
        },
        packageImg: {
          img_list: [],
        },
        encapsulationImg: {
          img_list: [],
        },
        loadImg: {
          img_list: [],
        },
        unloadImg: {
          img_list: [],
        },
        pulverizerImg: {
          img_list: [],
        },
        oveyImg: {
          img_list: [],
        },
      },
    };
  },
  mounted() {
    this.handleDetail();
  },
  filters: {
    timeChange(e) {
      return moment(e).format("YYYY-MM-DD");
    },
  },
  methods: {
    handleDetail() {
      	this.treeId = this.$route.query.treeId
      	this.$axios(this.api + '/elimination/accurate', {
      		params: {
      			treeId: this.treeId
      		}
      	}).then(res => {
          let data = res.data
          this.details = data
          this.projectIds = data.projectId;
          if (this.projectIds === 7) {
            this.after1 = "封桩后";
            this.subsection1 = "封土后";
          } else {
            this.after1 = "封装截面";
            this.subsection1 = "树桩分段";
          }
          this.getDetail(data);
      	})
    },
    getDetail(data) {
	  // 地径测量
	  if (data.diameterImg.length !== 0) {
        var img_list10 = [];
        data.diameterImg.forEach((res) => {
          img_list10.push("/" + res.imgPrefix + res.imgSrc);
        });
        var diameterImg = {
          createTime: data.diameterImg[0].createTime,
          work_user: data.groupName,
          img_list: img_list10,
        };
      } else {
        var diameterImg = {
          createTime: "",
          work_user: "",
          img_list: [],
        };
      }
      //砍伐前
      if (data.beforeImg.length !== 0) {
        var img_list1 = [];
        data.beforeImg.forEach((res) => {
          img_list1.push("/" + res.imgPrefix + res.imgSrc);
        });
        var beforeImg = {
          createTime: data.beforeImg[0].createTime,
          work_user: data.groupName,
          img_list: img_list1,
        };
      } else {
        var beforeImg = {
          createTime: "",
          work_user: "",
          img_list: [],
        };
      }
      //砍伐后
      if (data.afterImg.length !== 0) {
        var img_list2 = [];
        data.afterImg.forEach((res) => {
          img_list2.push("/" + res.imgPrefix + res.imgSrc);
        });
        var afterImg = {
          createTime: data.afterImg[0].createTime,
          work_user: data.groupName,
          img_list: img_list2,
        };
      } else {
        var afterImg = {
          createTime: "",
          work_user: "",
          img_list: [],
        };
      }
      //树桩分段
      if (data.subsectionImg.length !== 0) {
        var img_list3 = [];
        data.subsectionImg.forEach((res) => {
          img_list3.push("/" + res.imgPrefix + res.imgSrc);
        });
        var subsectionImg = {
          createTime: data.subsectionImg[0].createTime,
          work_user: data.groupName,
          img_list: img_list3,
        };
      } else {
        var subsectionImg = {
          createTime: "",
          work_user: "",
          img_list: [],
        };
      }
      if (data.projectName !== "") {
        //枝丫打包
        if (data.packageImg.length !== 0) {
          var img_list4 = [];
          data.packageImg.forEach((res) => {
            img_list4.push("/" + res.imgPrefix + res.imgSrc);
          });
          var packageImg = {
            createTime: data.packageImg[0].createTime,
            work_user: data.groupName,
            img_list: img_list4,
          };
        } else {
          var packageImg = {
            createTime: "",
            work_user: "",
            img_list: [],
          };
        }
        //封桩后
        if (data.encapsulationImg.length !== 0) {
          var img_list5 = [];
          data.encapsulationImg.forEach((res) => {
            img_list5.push("/" + res.imgPrefix + res.imgSrc);
          });
          var encapsulationImg = {
            createTime: data.encapsulationImg[0].createTime,
            work_user: data.groupName,
            img_list: img_list5,
          };
        } else {
          var encapsulationImg = {
            createTime: "",
            work_user: "",
            img_list: [],
          };
        }
        //运输装车
        if (data.loadImg.length !== 0) {
          var img_list6 = [];
          data.loadImg.forEach((res) => {
            img_list6.push("/" + res.imgPrefix + res.imgSrc);
          });
          var loadImg = {
            createTime: data.loadImg[0].createTime,
            work_user: data.groupName,
            img_list: img_list6,
          };
        } else {
          var loadImg = {
            createTime: "",
            work_user: "",
            img_list: [],
          };
        }
        //运输卸车
        if (data.unloadImg.length !== 0) {
          var img_list7 = [];
          data.unloadImg.forEach((res) => {
            img_list7.push("/" + res.imgPrefix + res.imgSrc);
          });
          var unloadImg = {
            createTime: data.unloadImg[0].createTime,
            work_user: data.groupName,
            img_list: img_list7,
          };
        } else {
          var unloadImg = {
            createTime: "",
            work_user: "",
            img_list: [],
          };
        }
        //粉碎
        if (data.pulverizerImg.length !== 0) {
          var img_list8 = [];
          data.pulverizerImg.forEach((res) => {
            img_list8.push("/" + res.imgPrefix + res.imgSrc);
          });
          var pulverizerImg = {
            createTime: data.pulverizerImg[0].createTime,
            work_user: data.groupName,
            img_list: img_list8,
          };
        } else {
          var pulverizerImg = {
            createTime: "",
            work_user: "",
            img_list: [],
          };
        }
        // 奥维定位
        if (data.oveyImg.length !== 0) {
          var img_list8 = [];
          data.oveyImg.forEach((res) => {
            img_list8.push("/" + res.imgPrefix + res.imgSrc);
          });
          var oveyImg = {
            createTime: data.oveyImg[0].createTime,
            work_user: data.groupName,
            img_list: img_list8,
          };
        } else {
          var oveyImg = {
            createTime: "",
            work_user: "",
            img_list: [],
          };
        }
      }
      if (data.projectName !== "") {
        this.detail = {
          ...data,
          beforeImg,
		      diameterImg,
          afterImg,
          subsectionImg,
          packageImg,
          encapsulationImg,
          loadImg,
          unloadImg,
          pulverizerImg,
          oveyImg
        };
      } else {
        this.detail = {
          ...data,
          beforeImg,
          afterImg,
          subsectionImg,
        };
      }
    },
    handleShow(show) {
      console.log(show);
      this.show = show;
    },
    handleBack() {
      this.$router.push("/list");
    },
  },
};
</script>

<style scoped="scoped">
.admin {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 40px 20px 40px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 1;
}

div /deep/.ant-timeline-item-head {
  background-color: transparent !important;
}

.mianbao {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  height: calc(100% - 60px);
  border: 1px solid #016ae0;
  border-radius: 8px;
  margin-top: 10px;
  padding: 20px;
  color: #ffffff;
}

.title {
  text-align: left;
  font-size: 16px;
}

.table {
  width: 100%;
  margin: 20px 0;
  border: 1px solid #005ea1;
}

.num1,
.time1,
.beizhu1 {
  width: 100%;
  height: 50px;
  display: flex;

  /* align-items: center; */
}

.num1,
.time1 {
  border-bottom: 1px solid #005ea1;
}

.num1 > div,
.time1 > div,
.beizhu1 > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.binahao {
  width: 15%;
  background-color: #1f335f;
  border-right: 1px solid #005ea1;
}

.num {
  width: 15%;
}

.name {
  width: 20%;
}

.nitck_name {
  width: 20%;
}

.address {
  width: 55%;
  padding-left: 20px;
  justify-content: flex-start !important;
}

.beizhu1 {
  height: 100px;
}

.beizhu {
  width: 85%;
  padding-left: 20px;
  justify-content: flex-start !important;
}

.liucheng {
  /* background-color: red; */
  width: 100%;
  margin-top: 20px;
  height: calc(100% - 310px);
  overflow-y: auto;
  text-align: left;
  color: #ffffff;
  padding-top: 10px;
}

.ant-timeline {
  margin-left: 15%;
  color: #fff;
  width: 100%;
  width: 75%;
}

.kanfaqian {
  width: 80%;
  /* height:200px; */
  /* background-color: red; */
}

.time {
  margin-top: 20px;
  margin-left: 20px;
}

.img {
  margin-left: 20px;
}
</style>
